import * as React from 'react';
import { graphql } from 'gatsby';
import { STEP } from '@core/constants/cart';
import { useEntities } from '@core/hooks';
import CartRoute from '@routes/cart';
import BaseHead from '@components/Head';
import { PostgresData, Product, Bundle } from '@interface/gatsby';
import { PageProps } from '@interface/common';


type DataType = PostgresData<Record<'products', Product[]>> & PostgresData<Record<'bundles', Bundle[]>>;

export default function CartPage({ data, isAuthenticating, isFetchingCart }: PageProps<DataType>) {
  const products = useEntities<Product>(data.postgres.products);
  const bundles = useEntities<Bundle>(data.postgres.bundles);

  const layoutProps = { isAuthenticating, isFetchingCart };
  const routeProps = { ...layoutProps, products, bundles };

  return (
    <CartRoute
      {...routeProps}
      path="/"
      step={STEP.CART}
    />
  );
}

export function Head() {
  return <BaseHead title="Shopping Cart"/>;
}

export const allProductsQuery = graphql`
    query CartProducts {
        postgres {
            products: productsList(orderBy: CREATED_AT_DESC) {
                id
                title
                slug
                url
                price
                cover {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                category {
                    slug
                    description
                }
            }
            bundles: bundlesList(orderBy: CREATED_AT_DESC) {
                id
                title
                slug
                url
                price
                discount
                cover {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                items: bundlesProductsList {
                    product {
                        id
                    }
                }
            }
        }
    }
`;
